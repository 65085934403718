import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";

import { FaAndroid, FaAppStoreIos } from "react-icons/fa";

import { navigate } from "gatsby";
import logoIcon from "../images/logo_icon.png";

export const Navbar = ({ title, subtitle }) => {
  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <img
            onClick={() => navigate("/")}
            style={{ width: 200, cursor: "pointer" }}
            src={logoIcon}
          />
        </Grid>
      </Grid>
      <Typography variant="h1" style={{ textAlign: "center" }}>
        {title}
      </Typography>
      <Typography variant="h5" style={{ textAlign: "center", fontWeight: 300 }}>
        {subtitle}
      </Typography>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "0.5rem" }}
        justify="center"
      >
        <Grid item>
          <Button onClick={() => navigate("/about")}>About</Button>
        </Grid>
        <Grid item>
          <Button onClick={() => navigate("/contact")}>Contact</Button>
        </Grid>
        <Grid item>
          <Button onClick={() => navigate("/support")}>Support</Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        justify="center"
        style={{ marginTop: "2rem" }}
      >
        <Grid item>
          <Button
            style={{ backgroundColor: "rgba(0, 0, 0, 0.87)", color: "white" }}
            variant="contained"
            startIcon={<FaAndroid />}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.theketoroad.localketo"
              )
            }
          >
            Download for Android
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ backgroundColor: "rgba(0, 0, 0, 0.87)", color: "white" }}
            variant="contained"
            startIcon={<FaAppStoreIos />}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/local-keto/id1561387408"
              )
            }
          >
            Download for iOS
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
