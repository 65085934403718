import React from "react";

import { ThemeProvider, Grid } from "@material-ui/core";
import { theme } from "../theme";

import { Helmet } from "react-helmet";

import "../app.css";
import { Footer } from "./Footer";

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Local Keto</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff0038" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <Grid container justify="center">
        <Grid item xs={12} md={6} style={{ padding: "0.5rem" }}>
          {children}
        </Grid>
      </Grid>
      <Footer />
    </ThemeProvider>
  );
};
