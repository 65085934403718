import React from "react";

import { Grid, Button } from "@material-ui/core";

import { navigate } from "gatsby";

export const Footer = () => {
  return (
    <Grid container spacing={2} style={{ margin: "4rem 0" }} justify="center">
      <Grid item></Grid>
      <Button onClick={() => navigate("/privacy-policy")}>
        Privacy Policy
      </Button>
    </Grid>
  );
};
