import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  status: {
    dark: "#000000",
  },
  palette: {
    primary: {
      main: "#FF0038",
    },
  },
  shape: {
    borderRadius: 0,
  },
});
